import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

export type UseT4FeatureFlagsProps = {
	cash4Enabled: boolean;
	payments4Module: boolean;
	entity4StaffPii: boolean;
	sigmaStudioEnabled: boolean;
	investmentTransactionsEnabled: boolean;
	projectedTransactionsEnabled: boolean;
	sigmaDashboardEnabled: boolean;
	hidePowerBi: boolean;
};

export function useT4FeatureFlags(): UseT4FeatureFlagsProps {
	const flags = useFlags();

	const t4FeatureFlags = useMemo(
		() => ({
			cash4Enabled: flags['cash4CategorizationMvp'],
			payments4Module: flags['payments4Module'],
			entity4StaffPii: flags['entity4StaffPii'],
			sigmaStudioEnabled: flags['sigmaStudio'],
			investmentTransactionsEnabled: flags['showCash4InvestmentTransactions'],
			projectedTransactionsEnabled: flags['showCash4ProjectedTransactions'],
			sigmaDashboardEnabled: flags['sigmaDashboard'],
			hidePowerBi: flags['hidePowerBi'],
		}),
		[flags],
	);

	return t4FeatureFlags;
}
