import { Add, Balance, CurrencyExchange, SwapHoriz } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Button,
	Grid,
	Link,
	MenuItem,
	Tab,
	Tooltip,
	Typography,
} from '@mui/material';
import { GridColDef, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { ProjectedTransaction } from 'modules/clients/customer-api/src/api/cash4';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { brandColors } from 'shared/theme/brandColors';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';
import { getQueryParam } from 'shared/utilities/navigationUtilities';
import { stonlyData } from 'stonly/functions';
import {
	CurrencySource,
	defaultCurrencyCode,
	formatCurrency,
} from 'utilities/currencyUtils';
import { normalizeDebitCredit } from './utilities';
import { useProjectedTransactionsQuery } from '../_queries/useProjectedTransactionsQuery';
import { C4ProjectedEditModal } from '../_shared/_components/c4ProjectedEditModal';
import { useAccountIntegrationsContext } from '../accountIntegrations/providers/accountIntegrationsProviders';
import { C4TransactionsDrawer } from '../reconciliations/_components/c4TransactionsDrawer';
import { ReconciliationDrawer } from '../reconciliations/_components/reconciliationDrawer';
import { useReconciliationsContext } from '../reconciliations/_providers/reconciliationsProvider';
import T4Drawer from '../shared/components/T4SideDrawer/T4DrawerShell';
import {
	Action,
	Actions,
	ActionsEnum,
} from '../shared/components/T4ActionMenu';
import { ProjectedTransactionDrawer } from './components/ProjectedTransactions/ProjectedTransactionDrawer';
import { ProjectionViewDrawer } from './components/ProjectedTransactions/ProjectionViewDrawer';
import { CurrencyOption, TransactionListItem } from './models';
import { useTransactions } from './providers/useTransactions';
import { deleteProjectedTransactionApiCall } from './services';
import {
	getProjectedTransactionsColumnDefs,
	projectedTransactionsColumnVisibility,
	stonlyIds as stonly,
} from './utilities/index';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
	normalizeReconciliationStatus,
	ReconciliationStatus,
} from '../reconciliations/_hooks/useReconciliationCalculations';
import { useRowSelectionCalculations } from '../_shared/_utilities/useRowSelectionCalculations';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { C4AlertBold } from '../reconciliations/_components/c4AlertBold';
import AmountCurrencyTooltipGridCell from '../_shared/_components/AmountCurrencyTooltipGridCell';
export const dataTestIds = {
	categorizationInfoModal: 'categorization-info-modal',
	createProjectedTransactionButton: 'create-projected-item-button',
};

export const stonlyIds = {
	categorizationInfoModal: 'categorization-info-modal',

	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',
};

export type TransactionPageTab = 'reported' | 'projected';

export const TransactionsPage: FC = observer(() => {
	const { projectedTransactionsEnabled } = useT4FeatureFlags();
	const user = useUser();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { transactionId, projectionId } = useParams<{
		transactionId: string;
		projectionId: string;
	}>();
	const {
		dateRange,
		handleDateRangeChange,
		defaultDateRange,
		fetchTransactions,
		currencyOption,
		configurations,
		handleCurrencyOptionChange,
		deleteCashTransactionApiCall,
	} = useTransactions();
	const { setTransactionStartDate, setTransactionEndDate } =
		useSessionStorage();
	const {
		startReconciliationCreation,
		viewReconciliation,
		reconciliationQueryContext: { refetch: refetchReconciliations },
		setDateRange,
	} = useReconciliationsContext();

	const {
		updateCurrentDay,
		getCurrentDayRefreshStatus,
		getCurrentDayRefreshDate,
	} = useAccountIntegrationsContext();

	const {
		projectedTransactionStartDate,
		projectedTransactionEndDate,
		setProjectedTransactionStartDate,
		setProjectedTransactionEndDate,
	} = useSessionStorage();

	const [projectedTransactionDateRange, setProjectedTransactionDateRange] =
		useState<DateRange<Moment>>([
			projectedTransactionStartDate,
			projectedTransactionEndDate,
		]);

	const handleProjectedTransactionDateRangeChange = useCallback(
		(dateRange: DateRange<Moment>) => {
			setProjectedTransactionDateRange(dateRange);

			// Update session variables
			if (dateRange[0]) {
				setProjectedTransactionStartDate(dateRange[0]);
			}
			if (dateRange[1]) {
				setProjectedTransactionEndDate(dateRange[1]);
			}
		},
		[setProjectedTransactionEndDate, setProjectedTransactionStartDate],
	);

	const {
		loading: projectedTransactionsLoading,
		data: projectedTransactions,
		refetch: refetchProjectedTransactions,
	} = useProjectedTransactionsQuery(
		{
			startDate: projectedTransactionDateRange[0]?.format('YYYY-MM-DD'),
			endDate: projectedTransactionDateRange[1]?.format('YYYY-MM-DD'),
			legalEntityGroupIds,
		},
		configurations?.reportingCurrencyCode,
	);

	const [projectedTransaction, setProjectedTransaction] =
		useState<ProjectedTransaction>();
	const [transaction, setTransaction] = useState<TransactionListItem>();
	const [openTransactionDrawer, setOpenTransactionDrawer] =
		useState<boolean>(false);
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);
	const [isEditWarningOpen, setIsEditWarningOpen] = useState(false);

	const [isCreateEditDrawerOpen, setIsCreatedEditDrawerOpen] =
		useState<boolean>(false);

	const [selectedProjectedIds, setSelectedProjectedIds] = useState<string[]>(
		[],
	);
	const [selectedCashTransactions, setSelectedCashTransactions] = useState<
		TransactionListItem[]
	>([]);
	const handleCloseTransactionDrawer = useCallback(() => {
		setOpenTransactionDrawer(false);
		history.push(paths.cash4.transactions.href);
	}, [history]);

	const handleCloseProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(false);
		history.push(`${paths.cash4.transactions.href}?tab=projected`);
	}, [history]);

	const handleOpenTransactionDrawer = () => {
		setOpenTransactionDrawer(true);
	};

	const handleOpenProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(true);
	}, []);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleCreateProjectedTransactionClick = useCallback(() => {
		setProjectedTransaction(undefined);
		setIsCreatedEditDrawerOpen(true);
	}, []);

	const handleProjectedViewClick = useCallback(
		(projection: ProjectedTransaction) => {
			setProjectedTransaction(projection);
			history.push(
				`${paths.cash4.projectedTransactions.href}/${
					projection.id
				}?tab=${'projected'}`,
			);
			handleOpenProjectionDrawer();
		},
		[handleOpenProjectionDrawer, history],
	);

	const handleProjectedEditClick = useCallback(
		(projection: ProjectedTransaction) => {
			setProjectedTransaction(projection);
			if (projection.reconciliationId) {
				setIsEditWarningOpen(true);
			} else {
				setIsCreatedEditDrawerOpen(true);
				setOpenProjectionDrawer(false);
			}
		},
		[setIsCreatedEditDrawerOpen, setIsEditWarningOpen],
	);

	const deleteTransaction = (data: { transactionId: string }) => {
		const { transactionId } = data;
		setConfirmationModalLoading(true);
		return deleteCashTransactionApiCall(transactionId);
	};

	const mutation = useMutation(deleteTransaction, {
		onSuccess: (data, variables) => {
			if (data) {
				handleCloseDialog();
				queryClient.invalidateQueries(['transactions']);
				enqueueSnackbar('Transaction deleted successfully.', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar('Error deleting transaction.', {
					variant: 'error',
				});
			}
			setConfirmationModalLoading(false);
		},
	});

	const handleDeleteCashTransaction = useCallback(
		(transactionId: string) => {
			return mutation.mutate({ transactionId: transactionId });
		},
		[mutation],
	);

	const handleCashTransactionDeleteClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedDialog({
				title: 'Delete reported transaction?',
				text: 'This reported transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					try {
						handleDeleteCashTransaction(transaction.id);
						setOpenTransactionDrawer(false);
					} catch {}
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);
			handleOpenDialog();
		},
		[handleDeleteCashTransaction],
	);

	const handleCashTransactionReconcileClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedCashTransactions((t) => [...t, ...[transaction]]);
		},
		[],
	);

	useEffect(() => {
		const transactionDateRange = [
			selectedCashTransactions
				.map((ct) => moment(ct.date))
				.sort((a, b) => a.diff(b))[0],
			selectedCashTransactions
				.map((ct) => moment(ct.date))
				.sort((a, b) => a.diff(b))
				[selectedCashTransactions.length - 1]?.add(1, 'day'),
		];
		if (!!transactionDateRange[0] && !!transactionDateRange[1]) {
			setDateRange(transactionDateRange as DateRange<Moment>);
			startReconciliationCreation();
		}
	}, [selectedCashTransactions, setDateRange, startReconciliationCreation]);

	const deleteProjectedTransaction = (data: {
		ProjectedTransactionId: string;
	}) => {
		const { ProjectedTransactionId } = data;
		return deleteProjectedTransactionApiCall(ProjectedTransactionId);
	};

	const projectedTransactionMutation = useMutation(deleteProjectedTransaction, {
		onSuccess: () => {
			refetchProjectedTransactions();
			enqueueSnackbar('The projected transaction was successfully deleted!', {
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar(
				'An error occurred and the projected transaction could not be deleted.',
				{
					variant: 'error',
					autoHideDuration: 10000,
				},
			);
		},
	});

	const handleDeleteProjectedTransaction = useCallback(
		(projectedTransactionId: string) => {
			setConfirmationModalLoading(true);
			try {
				if (projectedTransactionId) {
					projectedTransactionMutation.mutate({
						ProjectedTransactionId: projectedTransactionId,
					});
				}
				handleCloseDialog();
				if (openProjectionDrawer) {
					handleCloseProjectionDrawer();
				}
			} catch {
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[
			handleCloseProjectionDrawer,
			openProjectionDrawer,
			projectedTransactionMutation,
		],
	);

	const handleProjectedTransactionDeleteClick = useCallback(
		(row: ProjectedTransaction) => {
			setSelectedDialog({
				title: 'Delete projected transaction?',
				text: 'This projected transaction will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteProjectedTransaction(row.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);

			handleOpenDialog();
		},
		[handleDeleteProjectedTransaction],
	);

	const handleProjectedTransactionReconcileClick = useCallback(
		(row: ProjectedTransaction) => {
			setSelectedProjectedIds([row.id]);
			startReconciliationCreation();
		},
		[startReconciliationCreation],
	);

	const createProjectedTransactionButton = useMemo(
		() => (
			<Button
				startIcon={<Add />}
				type="button"
				variant="outlined"
				color="primary"
				data-testid={dataTestIds.createProjectedTransactionButton}
				{...stonlyData({
					id: stonlyIds.projectedTransactionsCreateButton,
				})}
				onClick={() => handleCreateProjectedTransactionClick()}
			>
				Projection
			</Button>
		),
		[handleCreateProjectedTransactionClick],
	);

	const CreateEditDrawer = useMemo(
		() => (
			<ProjectedTransactionDrawer
				isOpen={isCreateEditDrawerOpen}
				projectedTransaction={projectedTransaction}
				onClose={() => {
					setIsCreatedEditDrawerOpen(false);
					setProjectedTransaction(undefined);
				}}
				onSubmit={() => {
					refetchProjectedTransactions();
					refetchReconciliations();
				}}
			/>
		),
		[
			isCreateEditDrawerOpen,
			projectedTransaction,
			refetchProjectedTransactions,
			refetchReconciliations,
		],
	);

	useEffect(() => {
		dateRange[0] && setTransactionStartDate(dateRange[0]);
		dateRange[1] && setTransactionEndDate(dateRange[1]);
	}, [dateRange, setTransactionEndDate, setTransactionStartDate]);

	const { data, isLoading, error } = useQuery(
		['transactions', dateRange, legalEntityGroupIds],
		() => fetchTransactions(dateRange, legalEntityGroupIds),
		{
			refetchOnWindowFocus: false,
		},
	);

	const { data: currentDayRefreshStatus } = useQuery(
		['currentDayRefreshStatus'],
		() => getCurrentDayRefreshStatus(),
		{
			refetchOnWindowFocus: false,
			initialData: false,
		},
	);

	const { data: lastRefreshedDate } = useQuery(
		['lastRefreshedDate'],
		() => getCurrentDayRefreshDate(),
		{
			refetchOnWindowFocus: false,
			initialData: '',
		},
	);

	useEffect(() => {
		if (transactionId) {
			handleOpenTransactionDrawer();
		}
	}, [transactionId]);

	useEffect(() => {
		if (projectionId) {
			handleOpenProjectionDrawer();
		}
	}, [handleOpenProjectionDrawer, projectionId]);

	const tabValue = useMemo(() => {
		return getQueryParam(history.location.search, 'tab') ?? 'reported';
	}, [history.location.search]);

	const handleTransactionViewClick = useCallback(
		(transaction: TransactionListItem) => {
			setTransaction(transaction);
			history.push(`${paths.cash4.transactions.href}/${transaction.id}`);
			if (openTransactionDrawer) {
				handleCloseTransactionDrawer();
			} else if (openProjectionDrawer) {
				handleCloseProjectionDrawer();
			}
		},
		[
			handleCloseProjectionDrawer,
			handleCloseTransactionDrawer,
			history,
			openProjectionDrawer,
			openTransactionDrawer,
		],
	);

	const handleReconciliationSuccess = useCallback(() => {
		refetchProjectedTransactions();
		queryClient.invalidateQueries(['transactions']);
		setOpenTransactionDrawer(false);
	}, [queryClient, refetchProjectedTransactions]);

	const reconciliationDrawer = useMemo(() => {
		return (
			<ReconciliationDrawer
				onSuccess={handleReconciliationSuccess}
				onClose={() => {
					setSelectedProjectedIds([]);
					setSelectedCashTransactions([]);
				}}
				projectedTransactionIds={selectedProjectedIds}
				reportedTransactionProps={
					selectedCashTransactions?.length > 0
						? {
								reportedTransactionIds: selectedCashTransactions.map(
									(ct) => ct.id,
								),
								dateRange: [
									selectedCashTransactions
										.map((ct) => moment(ct.date))
										.sort((a, b) => a.diff(b))[0],
									selectedCashTransactions
										.map((ct) => moment(ct.date))
										.sort((a, b) => a.diff(b))
										[selectedCashTransactions.length - 1]?.add(1, 'day'),
								],
						  }
						: undefined
				}
			/>
		);
	}, [
		handleReconciliationSuccess,
		selectedProjectedIds,
		selectedCashTransactions,
	]);

	const columns = useMemo<GridColDef[]>(() => {
		const columns: GridColDef<TransactionListItem>[] = [
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							objecttype="Transaction"
							stonlyId={stonlyIds.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleTransactionViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationRecordId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleCashTransactionDeleteClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor && !params.row.reconciliationRecordId
									? [
											{
												action: ActionsEnum.reconcile,
												callback: () =>
													handleCashTransactionReconcileClick(params.row),
											},
									  ]
									: []),
							]}
							id="transactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				...getDateColumnDefinition(),
				field: 'date',
				headerName: 'Post Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				headerClassName: 'wrap-column-header',
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
			},
			{
				field: 'c4AccountNumber',
				headerName: 'C4 Account Number',
			},
			{
				field: 'transactionCode',
				headerName: 'Bank Transaction Code',
			},
			{
				field: 'transactionCodeDescription',
				headerName: 'Transaction Code Description',
			},
			{
				field: 'bankReference',
				headerName: 'Bank Reference',
			},
			{
				field: 'customerReference',
				headerName: 'Customer Reference',
			},
			{
				field: 'checkNumber',
				headerName: 'Check Number',
			},
			{
				field: 'detail',
				headerName: 'Transaction Details',
			},
			{
				field: 'noteContent',
				headerName: 'Notes',
			},
			{
				field: 'bankTransactionId',
				headerName: 'Bank Transaction Id',
			},
			{
				field: 'transactionRuleName',
				headerName: 'Rule Name',
				valueGetter: (params) => {
					return params.row.id === params.row.transactionRuleName
						? 'Manual Categorization'
						: params.row.transactionRuleName || 'No Rule Matched';
				},
				valueFormatter: (params) => params.value,
			},
			{
				field: 'cfc',
				headerName: 'CFC',
				description: 'Cash Flow Class',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfcName}>
							<Typography variant="body2" noWrap>
								{value.row.cfc}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfcName',
				headerName: 'CFC Description',
				description: 'Cash Flow Class',
			},
			{
				field: 'cft',
				headerName: 'CFT',
				description: 'Cash Flow Type',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cftName}>
							<Typography variant="body2" noWrap>
								{value.row.cft}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cftName',
				headerName: 'CFT Description',
				description: 'Cash Flow Type',
			},
			{
				field: 'cfst',
				headerName: 'CFST',
				description: 'Cash Flow Subtype',
				width: DataGridColumnWidths.fourChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfstName}>
							<Typography variant="body2" noWrap>
								{value.row.cfst}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfstName',
				headerName: 'CFST Description',
				description: 'Cash Flow Subtype',
			},
			{
				field: 'glNumber',
				headerName: 'GL Code',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'number',
				headerName: 'Amount',
				valueGetter: (params) => {
					if (currencyOption.id === 'original') {
						return params.row.number.accountCurrencyAmount;
					} else {
						return params.row.number.reportingCurrencyAmount;
					}
				},
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.number.accountCurrencyAmount,
						accountCurrencyCode: params.row.number.accountCurrencyCode,
						reportingCurrencyAmount: params.row.number.reportingCurrencyAmount,
						reportingCurrencyCode: params.row.number.reportingCurrencyCode,
						displayedCurrencyCode:
							currencyOption.id === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				field: 'currency',
				headerName: 'Currency',
				renderCell: (params) => {
					return currencyOption.id === 'original'
						? params.row.currency?.toUpperCase()
						: params.row.number.reportingCurrencyCode;
				},
			},
		];

		if (projectedTransactionsEnabled) {
			columns.push({
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => params.row.reconciliationStatus,
				valueFormatter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value ===
								normalizeReconciliationStatus(ReconciliationStatus.Posted)
									? 'The cleared transaction has no associated records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationRecordId) {
										viewReconciliation(params.row.reconciliationRecordId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationRecordId
										? 'underline'
										: undefined,
									color: params.row.reconciliationRecordId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationRecordId
										? 'pointer'
										: undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			});
		}

		columns.push(
			{
				field: 'bankName',
				headerName: 'Counterparty Name',
			},
			{
				field: 'bankCode',
				headerName: 'Counterparty Code',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'importedDate',
				headerName: 'Imported Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'valueDate',
				headerName: 'Value Date',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'lastUpdatedDate',
				headerName: 'Last Updated Date',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'transactionDateTime',
				headerName: 'Transaction Date',
			},
			{
				field: 'debitCredit',
				headerName: 'Debit/Credit',
				valueGetter: (params) => normalizeDebitCredit(params.value),
			},
		);

		if (user.cash4.isAuthor) {
			columns.push({
				field: 'isForecastModelExcluded',
				headerName: 'Forecast Reports',
				valueGetter: (params) => params.row.isForecastModelExcluded,
				valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
			});
		}

		return columns;
	}, [
		configurations?.reportingCurrencyCode,
		currencyOption.id,
		handleCashTransactionDeleteClick,
		handleCashTransactionReconcileClick,
		handleTransactionViewClick,
		projectedTransactionsEnabled,
		user.cash4.isAuthor,
		viewReconciliation,
	]);

	const projectedTransactionsColumns = useMemo<GridColDef[]>(() => {
		return getProjectedTransactionsColumnDefs(
			user.cash4.isAuthor,
			{
				...getCurrencyColumnDefinition(),
				field: 'amount',
				headerName: 'Amount',
				valueGetter: (params) => params.row.amount.accountCurrencyAmount,
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.amount.accountCurrencyAmount,
						accountCurrencyCode: params.row.amount.accountCurrencyCode,
						reportingCurrencyAmount:
							params.row.amount.reportingCurrencyAmount || null,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode:
							currencyOption.id === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				field: 'currencyCode',
				headerName: 'Currency Code',
				valueGetter: (params) => {
					return currencyOption.id === 'reporting'
						? params.row.amount.reportingCurrencyCode
						: params.row.amount.accountCurrencyCode;
				},
			},
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<Actions
							objecttype="Transaction"
							stonlyId={stonlyIds.projectedTransactionsRowContextMenu}
							actions={[
								{
									action: ActionsEnum.view,
									callback: () => handleProjectedViewClick(params.row),
								},
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.edit,
												callback: () => handleProjectedEditClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor
									? [
											{
												action: ActionsEnum.delete,
												isDisabled: params.row.reconciliationId
													? true
													: undefined,
												isDisabledTooltipText:
													'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
												callback: () =>
													handleProjectedTransactionDeleteClick(params.row),
											},
									  ]
									: []),
								...(user.cash4.isAuthor && !params.row.reconciliationId
									? [
											{
												action: ActionsEnum.reconcile,
												isDisabledTooltipText:
													'This record is already associated with a reconciliation. Edit reconciliation to remove association.',
												callback: () =>
													handleProjectedTransactionReconcileClick(params.row),
											},
									  ]
									: []),
							]}
							id="projectedTransactionId-more-menu"
						/>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				valueGetter: (params) => params.row.reconciliationStatus,
				valueFormatter: (params) => normalizeReconciliationStatus(params.value),
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled'
									? 'Perform reconciliation to associate records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									if (params.row.reconciliationId) {
										viewReconciliation(params.row.reconciliationId);
									}
								}}
								sx={(theme) => ({
									textDecoration: params.row.reconciliationId
										? 'underline'
										: undefined,
									color: params.row.reconciliationId
										? theme.palette.denim[500]
										: undefined,
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: params.row.reconciliationId ? 'pointer' : undefined,
								})}
							>
								{normalizeReconciliationStatus(params.value)}
							</Typography>
						</Tooltip>
					);
				},
			},
		);
	}, [
		user.cash4.isAuthor,
		handleProjectedViewClick,
		handleProjectedEditClick,
		handleProjectedTransactionDeleteClick,
		handleProjectedTransactionReconcileClick,
		viewReconciliation,
		configurations?.reportingCurrencyCode,
		currencyOption.id,
	]);

	const customNoProjectedTransactionsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={Balance}
				heading={
					<Typography variant="h3">No Projected Transactions</Typography>
				}
				body={
					user.cash4.isAuthor ? (
						<Typography variant="body1">
							Select{' '}
							<Link
								underline="always"
								color={brandColors.denim[500]}
								sx={{ cursor: 'pointer' }}
								onClick={() => setIsCreatedEditDrawerOpen(true)}
							>
								Create Projection
							</Link>{' '}
							to add your data, or adjust the date range to view additional
							transactions.
						</Typography>
					) : (
						<Typography variant="body1">
							Adjust your date range to view projected transactions or contact
							your administrator to add data.
						</Typography>
					)
				}
			/>
		),
		[user.cash4.isAuthor],
	);

	const reconcileButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<SwapHoriz />}
				onClick={() => {
					startReconciliationCreation();
				}}
			>
				Reconcile
			</T4Button>
		),
		[startReconciliationCreation],
	);

	const updateCurrentDayButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<CurrencyExchange />}
				onClick={() => {
					updateCurrentDay();
				}}
			>
				Update
			</T4Button>
		),
		[updateCurrentDay],
	);

	const reportedSelection = useRowSelectionCalculations(
		data?.map((x) => ({
			id: x.id,
			accountAmount: x.number.accountCurrencyAmount,
			accountCurrencyCode: x.currency,
			reportingAmount: x.number.reportingCurrencyAmount,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const projectedSelection = useRowSelectionCalculations(
		projectedTransactions?.map((pt: ProjectedTransaction) => ({
			id: pt.id,
			accountAmount: pt.amount.accountCurrencyAmount,
			accountCurrencyCode: pt.amount.accountCurrencyCode,
			reportingAmount: pt.amount.reportingCurrencyAmount,
			reportingCurrencyCode:
				pt.amount.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const reportedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				tableKey="transactionsPage"
				columns={columns}
				stonlyId="transactions"
				rows={data || []}
				loading={isLoading}
				calculateColumnWidths
				columnVisibilityModel={{
					c4AccountNumber: false,
					bankName: false,
					bankCode: false,
					bankReference: false,
					checkNumber: false,
					glNumber: false,
					customerReference: false,
					transactionImportedDate: false,
					transactionRuleName: false,
					fiTransactionId: false,
					noteContent: false,
					cfcName: false,
					cftName: false,
					cfstName: false,
					isForecastModelExcluded: false,
					debitCredit: false,
					valueDate: false,
					transactionDateTime: false,
					lastUpdatedDate: false,
					transactionCodeDescription: false,
				}}
				getRowClassName={(
					params: GridRowClassNameParams<TransactionListItem>,
				) => (!params.row.cfc ? 'uncategorized-row' : '')}
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'date', sort: 'desc' },
							{ field: 'e4AccountNumber', sort: 'asc' },
							{ field: 'transactionCode', sort: 'asc' },
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				onRowSelectionModelChange={reportedSelection.setRowSelectionHandler}
			/>
		),
		[columns, data, isLoading, reportedSelection],
	);

	const projectedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				stonlyId={stonly.projectedTransactionsGrid}
				tableKey="projectedTransactionsPage"
				loading={projectedTransactionsLoading}
				columns={projectedTransactionsColumns}
				columnVisibilityModel={projectedTransactionsColumnVisibility}
				rows={projectedTransactions || []}
				calculateColumnWidths
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'expectedValueDate',
								sort: 'asc',
							},
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				pagination
				hideFooter={false}
				autoPageSize
				slots={{ noRowsOverlay: customNoProjectedTransactionsOverlay }}
				onRowSelectionModelChange={projectedSelection.setRowSelectionHandler}
			/>
		),
		[
			projectedTransactions,
			projectedTransactionsLoading,
			projectedTransactionsColumns,
			customNoProjectedTransactionsOverlay,
			projectedSelection,
		],
	);

	const getTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			const actions: Action[] = [
				{
					action: ActionsEnum.delete,
					isDisabled: transaction?.reconciliationRecordId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () => handleCashTransactionDeleteClick(transaction!),
				},
			];

			if (!transaction?.reconciliationRecordId) {
				actions.push({
					action: ActionsEnum.reconcile,
					callback: () => handleCashTransactionReconcileClick(transaction!),
				});
			}
			return actions;
		} else {
			return undefined;
		}
	}, [
		handleCashTransactionDeleteClick,
		handleCashTransactionReconcileClick,
		transaction,
		user.cash4.isAuthor,
	]);

	const getProjectedTransactionActions = useMemo((): Action[] | undefined => {
		if (user.cash4.isAuthor) {
			const actions: Action[] = [
				{
					action: ActionsEnum.delete,
					isDisabled: projectedTransaction?.reconciliationId ? true : undefined,
					isDisabledTooltipText:
						'This record is associated with a reconciliation and cannot be deleted. Edit reconciliation to remove association.',
					callback: () =>
						handleProjectedTransactionDeleteClick(projectedTransaction!),
				},
				{
					action: ActionsEnum.edit,
					callback: () => handleProjectedEditClick(projectedTransaction!),
				},
			];
			if (!projectedTransaction?.reconciliationId) {
				actions.push({
					action: ActionsEnum.reconcile,
					callback: () =>
						handleProjectedTransactionReconcileClick(projectedTransaction!),
				});
			}
			return actions;
		} else {
			return undefined;
		}
	}, [
		user.cash4.isAuthor,
		projectedTransaction,
		handleProjectedTransactionDeleteClick,
		handleProjectedEditClick,
		handleProjectedTransactionReconcileClick,
	]);

	useEffect(() => {
		if (!tabValue) {
			history.push(history.location.pathname + `?tab=${'reported'}`);
		}
	}, [history, tabValue]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.transactionsPage}
					title="Transactions"
				/>
			}
		>
			<TabContext value={tabValue}>
				<Grid
					container
					wrap="nowrap"
					sx={{
						gap: 2,
						flexDirection: 'column',
						height: '100%',
						width: '100%',
					}}
				>
					<Grid container item xs="auto">
						<TabList
							onChange={(_, tab) => {
								const setTab = (tab: TransactionPageTab) => {
									history.push(history.location.pathname + `?tab=${tab}`);
								};

								if (tab === 'reported') {
									setTab(tab);
								} else if (tab === 'projected') {
									setTab(tab);
								} else {
									setTab('reported');
								}
							}}
						>
							<Tab label="Reported" value="reported" />
							{projectedTransactionsEnabled && (
								<Tab label="Projected" value="projected" />
							)}
						</TabList>
					</Grid>
					<Grid item xs={true}>
						<TabPanel value="reported" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								direction="column"
								sx={{
									gap: 2,
									height: '100%',
									flexWrap: 'nowrap',
								}}
							>
								<Grid container item spacing={2}>
									<Grid item container sm={12} md="auto">
										<T4DateRangePicker
											defaultValue={dateRange}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate)
												) {
													if (
														!startDate.isSame(dateRange[0]) ||
														!endDate.isSame(dateRange[1])
													) {
														handleDateRangeChange([startDate, endDate]);
													}
												}
											}}
											maxDate={moment()}
											disableFuture
											showShortcuts
											shortcutResetDefaults={defaultDateRange}
											sx={{ marginBottom: 0 }}
										/>
									</Grid>
									<Grid item container sm={12} md="auto">
										<T4TextFieldV2
											label="Display Currency"
											select
											value={currencyOption.id}
											onChange={(event) => {
												handleCurrencyOptionChange(
													event as CurrencyOption['id'],
												);
											}}
										>
											{[
												{ id: 'original', value: 'Account Currency' },
												{
													id: 'reporting',
													value: `Reporting Currency (${
														configurations?.reportingCurrencyCode || 'USD'
													})`,
												},
											].map((x) => {
												return (
													<MenuItem key={x.id} value={x.id}>
														{x.value}
													</MenuItem>
												);
											})}
										</T4TextFieldV2>
									</Grid>
									<Grid item container sm={12} md="auto">
										<LegalEntityGroupsFilter loading={isLoading} />
									</Grid>
									<Grid
										item
										container
										sm={12}
										lg="auto"
										spacing={2}
										sx={{
											alignContent: 'flex-end',
											'@media (min-width: 1640px)': {
												marginLeft: 'auto',
											},
										}}
									>
										{user.cash4.isAuthor && currentDayRefreshStatus && (
											<Tooltip title={lastRefreshedDate}>
												<Grid item container sm={6} md="auto">
													{updateCurrentDayButton}
												</Grid>
											</Tooltip>
										)}
										{user.cash4.isAuthor && projectedTransactionsEnabled && (
											<Grid item container sm={6} md="auto">
												{createProjectedTransactionButton}
											</Grid>
										)}
										{user.cash4.isAuthor && projectedTransactionsEnabled && (
											<Grid item container sm={6} md="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								{error && (
									<Grid item xs="auto">
										<T4AlertStack error={error as string} />
									</Grid>
								)}
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper
										sx={{
											height: '100%',
											'& .uncategorized-row': {
												backgroundColor: '#FFFDE3',
											},
											'& .MuiDataGrid-columnHeaders': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeader': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeadersInner': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeaderTitle': {
												height: '3rem !important',
												maxHeight: '3rem !important',
												display: 'flex',
												flexWrap: 'wrap',
												whiteSpace: 'break-spaces',
												lineHeight: '1.5rem',
												alignContent: 'center',
											},
										}}
									>
										{reportedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
								{reportedSelection.isSelectedTotalValid &&
									reportedSelection.selectedCount >= 2 && (
										<Grid item xs="auto">
											<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
												{'The '}
												<C4AlertBold>{`${
													reportedSelection.selectedCurrencySource ===
													CurrencySource.Account
														? 'account currency'
														: 'reporting currency'
												}`}</C4AlertBold>
												{' total of the selected rows is '}
												<C4AlertBold>{`${formatCurrency(
													reportedSelection.selectedTotal,
													{
														currency: reportedSelection.selectedCurrency,
													},
												)} ${reportedSelection.selectedCurrency}`}</C4AlertBold>
												{'.'}
											</T4Alert>
										</Grid>
									)}
								{!reportedSelection.isSelectedTotalValid && (
									<Grid item xs="auto">
										<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
											{'The '}
											<C4AlertBold>{`${
												reportedSelection.selectedCurrencySource ===
												CurrencySource.Account
													? 'account currency'
													: 'reporting currency'
											}`}</C4AlertBold>
											{
												' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
											}
										</T4Alert>
									</Grid>
								)}
							</Grid>
						</TabPanel>

						<TabPanel value="projected" sx={{ height: '100%', padding: 0 }}>
							<Grid
								item
								container
								direction="column"
								sx={{
									gap: 2,
									height: '100%',
									flexWrap: 'nowrap',
								}}
							>
								<Grid container item spacing={2}>
									<Grid item container sm={12} md="auto">
										<T4DateRangePicker
											defaultValue={projectedTransactionDateRange}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate)
												) {
													if (
														!startDate.isSame(dateRange[0]) ||
														!endDate.isSame(dateRange[1])
													) {
														handleProjectedTransactionDateRangeChange([
															startDate,
															endDate,
														]);
													}
												}
											}}
											showShortcuts
											sx={{ marginBottom: 0 }}
										/>
									</Grid>
									<Grid item container sm={12} md="auto">
										<LegalEntityGroupsFilter loading={isLoading} />
									</Grid>
									<Grid
										item
										container
										sm={12}
										lg="auto"
										spacing={2}
										sx={{
											alignContent: 'flex-end',
											'@media (min-width: 1330px)': {
												marginLeft: 'auto',
											},
										}}
									>
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{createProjectedTransactionButton}
											</Grid>
										)}
										{user.cash4.isAuthor && (
											<Grid item container sm={6} md="auto">
												{reconcileButton}
											</Grid>
										)}
									</Grid>
								</Grid>
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper>
										{projectedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
								{projectedSelection.isSelectedTotalValid &&
									projectedSelection.selectedCount >= 2 && (
										<Grid item xs="auto">
											<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
												{'The '}
												<C4AlertBold>{`${
													projectedSelection.selectedCurrencySource ===
													CurrencySource.Account
														? 'account currency'
														: 'reporting currency'
												}`}</C4AlertBold>
												{' total of the selected rows is '}
												<C4AlertBold>{`${formatCurrency(
													projectedSelection.selectedTotal,
													{
														currency: projectedSelection.selectedCurrency,
													},
												)} ${
													projectedSelection.selectedCurrency
												}`}</C4AlertBold>
												{'.'}
											</T4Alert>
										</Grid>
									)}
								{!projectedSelection.isSelectedTotalValid && (
									<Grid item xs="auto">
										<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
											{'The '}
											<C4AlertBold>{`${
												projectedSelection.selectedCurrencySource ===
												CurrencySource.Account
													? 'account currency'
													: 'reporting currency'
											}`}</C4AlertBold>
											{
												' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
											}
										</T4Alert>
									</Grid>
								)}
							</Grid>
						</TabPanel>
					</Grid>
				</Grid>
			</TabContext>

			<C4TransactionsDrawer
				isOpen={openTransactionDrawer}
				onClose={handleCloseTransactionDrawer}
				transactionId={transactionId}
				disableLink={false}
				transactionActions={getTransactionActions}
			/>

			<T4Drawer
				open={openProjectionDrawer}
				onClose={handleCloseProjectionDrawer}
				stonlyPrefix="projected-transaction-details"
			>
				<ProjectionViewDrawer
					projectionId={projectionId}
					projectedTransactionActions={getProjectedTransactionActions}
				/>
			</T4Drawer>

			{reconciliationDrawer}

			<C4ProjectedEditModal
				isOpen={isEditWarningOpen}
				onCancel={() => {
					if (!openProjectionDrawer) {
						setProjectedTransaction(undefined);
					}
					setIsCreatedEditDrawerOpen(false);
					setIsEditWarningOpen(false);
				}}
				onConfirm={() => {
					setOpenProjectionDrawer(false);
					setIsCreatedEditDrawerOpen(true);
					setIsEditWarningOpen(false);
				}}
			/>

			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal: stonlyIds.transactionsDeleteConfirmationModal,
					secondaryButton: stonlyIds.transactionsDeleteConfirmationCancelButton,
					primaryButton: stonlyIds.transactionsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
			{CreateEditDrawer}
		</T4View>
	);
});
