import { SwapHoriz } from '@mui/icons-material';
import {
	Divider,
	Grid,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths, validIdRegex } from 'shared/constants/paths';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getPercentColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { ReconciliationDrawer } from './_components/reconciliationDrawer';
import {
	getCurrencyCode,
	normalizeReconciliationStatus,
	convertToPercentage,
} from './_hooks/useReconciliationCalculations';
import { useReconciliationsContext } from './_providers/reconciliationsProvider';
import { useUser } from 'shared/hooks/useUser';

export const stonlyIds = {
	confirmationModal: 'cash4-reconciliations-confirmation-modal',
	confirmationModalPrimaryButton:
		'cash4-reconciliations-confirmation-modal-accept',
	confirmationModalSecondaryButton:
		'cash4-reconciliations-confirmation-modal-cancel',
};

export const ReconciliationsPage: FC = observer(() => {
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedDialog, setSelectedDialog] =
		useState<
			Pick<
				IConfirmationDialogProps,
				| 'title'
				| 'text'
				| 'primaryButtonText'
				| 'secondaryButtonText'
				| 'onPrimaryButtonClick'
				| 'onSecondaryButtonClick'
			>
		>();
	const history = useHistory();
	const {
		reconciliationQueryContext: {
			loading: loadingReconciliations,
			data: reconciliations,
		},
		startReconciliationCreation,
		viewReconciliation,
		editReconciliation,
		deleteReconciliation,
		open: openReconciliation,
	} = useReconciliationsContext();

	const {
		cash4: { isAuthor },
	} = useUser();

	const onCreateReconciliationHandler = useCallback(() => {
		startReconciliationCreation();
	}, [startReconciliationCreation]);

	const onViewClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			if (reconciliation) {
				history.push(
					`${paths.cash4.reconciliations.href}/${reconciliation.id}`,
				);
			}
		},
		[history],
	);

	const onEditClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			if (reconciliation) {
				editReconciliation(reconciliation);
			}
		},
		[editReconciliation],
	);

	const handleOpenDialog = useCallback(() => {
		setOpenDialog(true);
	}, [setOpenDialog]);

	const handleCloseDialog = useCallback(() => {
		setOpenDialog(false);
	}, [setOpenDialog]);

	const handleDeleteReconciliation = useCallback(
		async (reconciliation: Reconciliation) => {
			try {
				if (reconciliation) {
					await deleteReconciliation(reconciliation);
				}
			} catch {
			} finally {
				handleCloseDialog();
			}
		},
		[deleteReconciliation, handleCloseDialog],
	);

	const onDeleteClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			setSelectedDialog({
				title: 'Delete reconciliation?',
				text: 'This reconciliation will be permanently deleted from the system. All associated transactions will become unreconciled. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				secondaryButtonText: 'CANCEL',
				onPrimaryButtonClick: async () => {
					await handleDeleteReconciliation(reconciliation);
				},
				onSecondaryButtonClick: handleCloseDialog,
			});

			handleOpenDialog();
		},
		[handleDeleteReconciliation, handleOpenDialog, handleCloseDialog],
	);

	const handleReconciliationDrawerClose = useCallback(() => {
		history.push(paths.cash4.reconciliations.href);
	}, [history]);

	const ReconciliationRoute: FC = useMemo(
		() =>
			observer(() => {
				const { id } = useParams<{ id: string }>();

				useEffect(() => {
					if (id) {
						viewReconciliation(id);
					}
				}, [id]);

				return null;
			}),
		[viewReconciliation],
	);

	useEffect(() => {
		if (!openReconciliation) {
			history.push(paths.cash4.reconciliations.href);
		}
	}, [history, openReconciliation]);

	const customNoRowsOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={SwapHoriz}
				heading={<Typography variant="h3">No Reconciliations</Typography>}
				body={
					<Typography>
						{'Select '}
						<Typography
							component={'span'}
							onClick={onCreateReconciliationHandler}
							sx={(theme) => ({
								textDecoration: 'underline',
								cursor: 'pointer',
								color: theme.palette.secondary.main,
							})}
						>
							reconcile
						</Typography>
						{' to create records.'}
					</Typography>
				}
			/>
		),
		[onCreateReconciliationHandler],
	);

	return (
		<T4View
			header={<PageHeader id={'reconciliations'} title="Reconciliations" />}
		>
			<Grid
				container
				item
				sx={{
					height: '100%',
					gap: 2,
					flexDirection: 'column',
					flexWrap: 'nowrap',
				}}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{ gap: 1, justifyContent: 'flex-end' }}
				>
					{isAuthor && (
						<Grid item xs="auto">
							<T4Button
								variant="outlined"
								startIcon={<SwapHoriz />}
								onClick={onCreateReconciliationHandler}
								sx={{ marginTop: '8px' }}
							>
								Reconcile
							</T4Button>
						</Grid>
					)}
				</Grid>
				<Grid item xs={true}>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<Reconciliation>
							stonlyId={'c4-reconciliations'}
							tableKey={'c4-reconciliations'}
							loading={loadingReconciliations}
							pagination
							autoPageSize
							showCustomViewButton
							showToolbar={true}
							hideFooter={false}
							rows={reconciliations}
							initialState={{
								sorting: {
									sortModel: [{ field: 'createdDate', sort: 'desc' }],
								},
							}}
							pinnedColumns={{
								left: [USER_PREFERENCES_FIELD_OPTIONS],
							}}
							slots={{ noRowsOverlay: customNoRowsOverlay }}
							columns={[
								{
									...getOptionsMenuColDef((params) => {
										return (
											<OverflowMenu id={'c4-reconciliations'}>
												<MenuItem
													onClick={() => onViewClickHandler(params.row)}
												>
													<ListItemText>View Details</ListItemText>
												</MenuItem>
												{isAuthor && (
													<MenuItem
														onClick={() => onEditClickHandler(params.row)}
													>
														<ListItemText>Edit Reconciliation</ListItemText>
													</MenuItem>
												)}
												{isAuthor && <Divider />}
												{isAuthor && (
													<DeleteMenuItem
														objecttype="Reconciliation"
														onClick={() => onDeleteClickHandler(params.row)}
													>
														Delete
													</DeleteMenuItem>
												)}
											</OverflowMenu>
										);
									}),
								},
								{
									field: 'status',
									headerName: 'Reconciliation Status',
									valueGetter: (params) =>
										params.row.summary.reconciliationStatus,
									valueFormatter: (params) =>
										normalizeReconciliationStatus(params.value),
								},
								{
									field: '_with',
									headerName: 'Reconciled With',
									valueGetter: (params) => {
										return (
											params.row.summary.projectedCount +
											params.row.summary.reportedCount
										);
									},
									valueFormatter: (params) => params.value,
									renderCell: (params) => {
										let formattedValue: string | undefined;
										if (params.value && typeof params.value === 'number') {
											const records = new Intl.NumberFormat('default').format(
												params.value,
											);
											formattedValue = `${records} transactions`;
										}

										return formattedValue;
									},
								},
								{
									...getPercentColumnDefinition(2, 2),
									field: '_variance',
									headerName: 'Variance Percentage',
									valueGetter: (params) =>
										params.row.summary.variancePercentage,
								},
								{
									...getCurrencyColumnDefinition(),
									field: '_variance_amount',
									headerName: 'Unreconciled Amount',
									valueGetter: (params) =>
										params.row.summary.unreconciledAmount,
									renderCell: (params) =>
										formatCurrency(params.value, {
											currency: getCurrencyCode(params.row),
										}),
								},
								{
									...getCurrencyColumnDefinition(),
									field: '_reconciled_amount',
									headerName: 'Reconciled Amount',
									valueGetter: (params) => params.row.summary.reconciledAmount,
									renderCell: (params) =>
										formatCurrency(params.value, {
											currency: getCurrencyCode(params.row),
										}),
								},
								{
									field: 'currencyCode',
									headerName: 'Currency',
									valueGetter: (params) => getCurrencyCode(params.row),
								},
								{
									...getDateColumnDefinition(),
									field: 'createdOn',
									headerName: 'Created On',
								},
								{
									field: 'createdBy',
									headerName: 'Created By',
								},
								{
									...getDateColumnDefinition(),
									field: 'updatedOn',
									headerName: 'Updated On',
								},
								{
									field: 'updatedBy',
									headerName: 'Updated By',
								},
								{
									field: 'notes',
									headerName: 'Notes',
								},
							]}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
			<Switch>
				<Route
					path={`${paths.cash4.reconciliations.href}/:id`.concat(validIdRegex)}
				>
					<ReconciliationRoute />
				</Route>
			</Switch>
			<ReconciliationDrawer onClose={handleReconciliationDrawerClose} />

			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog?.title ?? ''}
				text={selectedDialog?.text ?? ''}
				primaryButtonText={selectedDialog?.primaryButtonText}
				secondaryButtonText={selectedDialog?.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog?.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog?.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal: stonlyIds.confirmationModal,
					primaryButton: stonlyIds.confirmationModalPrimaryButton,
					secondaryButton: stonlyIds.confirmationModalSecondaryButton,
				}}
				loading={false}
			/>
		</T4View>
	);
});
