/* eslint-disable mobx/missing-observer */
import { TrendingUp } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import {
	GridColDef,
	GridRowSelectionModel,
	GridSortModel,
} from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery } from '@tanstack/react-query';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { useUser } from 'shared/hooks/useUser';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getDecimalColumnDefinition,
	getPercentColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { isValidDateRange } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';
import {
	CurrencySource,
	defaultCurrencyCode,
	formatCurrency,
} from 'utilities/currencyUtils';
import RowActionButton from '../components/RowActionButton';
import { Holdings } from '../models';
import { fetchHoldings } from '../services';
import { stonlyIds } from '../stonlyIds';
import { useRowSelectionCalculations } from 'features/cash4/_shared/_utilities/useRowSelectionCalculations';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { C4AlertBold } from 'features/cash4/reconciliations/_components/c4AlertBold';
import AmountCurrencyTooltipGridCell from 'features/cash4/_shared/_components/AmountCurrencyTooltipGridCell';
import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';

interface HoldingsRouteProps {}

const HoldingsRoute: React.FC<HoldingsRouteProps> = () => {
	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.holdingsPage}
					title="Investment Holdings"
				/>
			}
		>
			<HoldingsPage />
		</T4View>
	);
};

export default HoldingsRoute;

interface HoldingsPageProps {}

const HoldingsPage: React.FC<HoldingsPageProps> = () => {
	const statementDateColumnName = 'statementDate';
	const defaultDateRange: DateRange<Moment> = [
		moment().subtract(7, 'days').startOf('day'),
		moment().subtract(0, 'days').endOf('day'),
	];

	const [dateRange, setDateRange] =
		useState<DateRange<Moment>>(defaultDateRange);

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: statementDateColumnName,
			sort: 'desc',
		},
	]);

	const { cash4 } = useUser();
	const { data: configurations } = useCash4ConfigurationsQuery();

	const { data: holdings, isLoading: holdingsLoading } = useQuery(
		['holdings', dateRange],
		() => fetchHoldings(dateRange, configurations),
		{
			initialData: [],
		},
	);

	useEffect(() => {
		setSortModel([
			{
				field: statementDateColumnName,
				sort: 'desc',
			},
		]);
	}, [holdings]);

	const handleDateRangeChange = (dateRange: DateRange<Moment>) => {
		if (isValidDateRange(dateRange)) {
			setDateRange(dateRange);
		}
	};

	const getRowId = (row: any) => row.investmentHoldingId;

	const customNoRowOverlay = useMemo(
		() => () => (
			<NoRowsOverlay
				icon={TrendingUp}
				heading={<Typography variant="h3">No Holdings Found</Typography>}
				body={
					<Typography variant="body1">
						Looks like there are no investment holdings. Please adjust the date
						range or contact Customer Service at{' '}
						<a href="mailto:support@treasury4.com">support@treasury4.com</a> to
						add investment holding data.
					</Typography>
				}
			/>
		),
		[],
	);

	const marketValueSelection = useRowSelectionCalculations(
		holdings.map((b) => ({
			id: b.investmentHoldingId,
			accountAmount: b.marketValue.accountCurrencyAmount,
			accountCurrencyCode:
				b.marketValue.accountCurrencyCode || defaultCurrencyCode,
			reportingAmount: b.marketValue.reportingCurrencyAmount,
			reportingCurrencyCode:
				b.marketValue.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const columns = useMemo<GridColDef<Holdings>[]>(() => {
		const columns: GridColDef<Holdings>[] = [
			{
				field: 'cusip',
				headerName: 'CUSIP',
			},
			{
				field: 'isin',
				headerName: 'ISIN',
			},
			{
				...getDateColumnDefinition(),
				field: statementDateColumnName,
				headerName: 'Statement Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				width: 300,
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
			},
			{
				field: 'bankCode',
				headerName: 'Bank Code',
			},
			{
				field: 'currencyCode',
				headerName: 'Currency',
			},
			{
				...getDecimalColumnDefinition(),
				field: 'shares',
				headerName: 'Shares',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'accruedInterest',
				headerName: 'Accrued Interest',
				renderCell: (params) =>
					formatCurrency(params.row.accruedInterest, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'marketValue',
				headerName: 'Market Value',
				valueGetter: (params) => {
					return params.row.marketValue.accountCurrencyAmount;
				},
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.marketValue.accountCurrencyAmount,
						accountCurrencyCode: params.row.marketValue.accountCurrencyCode,
						reportingCurrencyAmount:
							params.row.marketValue.reportingCurrencyAmount || null,
						reportingCurrencyCode: params.row.marketValue.reportingCurrencyCode,
						displayedCurrencyCode: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'bookValue',
				headerName: 'Book Value',
				renderCell: (params) =>
					formatCurrency(params.row.bookValue, {
						currency: params.row.currencyCode,
					}),
			},
			{
				field: 'accountNumber',
				headerName: 'C4 Account Number',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'federalTaxCost',
				headerName: 'Federal Tax Cost',
				renderCell: (params) =>
					formatCurrency(params.row.federalTaxCost, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'unrealizedGainLossCost',
				headerName: 'Unrealized Gain/Loss Cost',
				renderCell: (params) =>
					formatCurrency(params.row.unrealizedGainLossCost, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingBalanceAmount',
				headerName: 'Opening Balance Amount',
				renderCell: (params) =>
					formatCurrency(params.row.openingBalanceAmount, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDecimalColumnDefinition(),
				field: 'openingBalanceUnits',
				headerName: 'Opening Balance Units',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingBalancePrice',
				headerName: 'Opening Balance Price',
				renderCell: (params) =>
					formatCurrency(params.row.openingBalancePrice, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'closingBalancePrice',
				headerName: 'Closing Balance Price',
				renderCell: (params) =>
					formatCurrency(params.row.closingBalancePrice, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'mtdEarnings',
				headerName: 'MTD Earnings',
				renderCell: (params) =>
					formatCurrency(params.row.mtdEarnings, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'baseSettledMarketValue',
				headerName: 'Base Settled Market Value',
				renderCell: (params) =>
					formatCurrency(params.row.baseSettledMarketValue, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'localSettledMarketValue',
				headerName: 'Local Settled Market Value',
				renderCell: (params) =>
					formatCurrency(params.row.localSettledMarketValue, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'localTradedMarketValue',
				headerName: 'Local Traded Market Value',
				renderCell: (params) =>
					formatCurrency(params.row.localTradedMarketValue, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDecimalColumnDefinition(),
				field: 'currentYield',
				headerName: 'Current Yield',
			},
			{
				...getDateColumnDefinition(),
				field: 'priceDate',
				headerName: 'Price Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'firstIncomePaymentDate',
				headerName: 'Next Two Income Payments 1',
			},
			{
				...getDateColumnDefinition(),
				field: 'secondIncomePaymentDate',
				headerName: 'Next Two Income Payments 2',
			},
			{
				...getDecimalColumnDefinition(),
				field: 'monthToDateEarningsRate',
				headerName: 'MTD Earnings Rate',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'monthToDateFees',
				headerName: 'MTD Fees',
				renderCell: (params) =>
					formatCurrency(params.row.monthToDateFees, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDecimalColumnDefinition(),
				field: 'monthToDateFeesRate',
				headerName: 'MTD Fees Rate',
			},
			{
				field: 'sourceAccountName',
				headerName: 'Source Account Name',
			},
			{
				field: 'portfolioId',
				headerName: 'Portfolio ID',
			},
			{
				field: 'portfolioTypeCode',
				headerName: 'Portfolio Type Code',
			},
			{
				field: 'portfolioTypeCodeDescription',
				headerName: 'Portfolio Type Code Description',
			},
			{
				field: 'portfolioName',
				headerName: 'Portfolio Name',
			},
			{
				field: 'custodianId',
				headerName: 'Custodian ID',
			},
			{
				field: 'custodianName',
				headerName: 'Custodian Name',
			},
			{
				field: 'subCustodianId',
				headerName: 'Sub-custodian ID',
			},
			{
				field: 'subCustodianName',
				headerName: 'Sub-custodian Name',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'baseMarketValuePlusAccrued',
				headerName: 'Base Market Value Plus Accrued',
				renderCell: (params) =>
					formatCurrency(params.row.baseMarketValuePlusAccrued, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'localAccruedInterest',
				headerName: 'Local Accrued Interest',
				renderCell: (params) =>
					formatCurrency(params.row.localAccruedInterest, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDateColumnDefinition(),
				field: 'tradeDate',
				headerName: 'Trade Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'settlementDate',
				headerName: 'Settlement Date',
			},
			{
				field: 'originalLotId',
				headerName: 'Original Lot Id',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'dividendAmount',
				headerName: 'Dividend Amount',
				renderCell: (params) =>
					formatCurrency(params.row.dividendAmount, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDateColumnDefinition(),
				field: 'expectedDividendDate',
				headerName: 'Ex Dividend Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'recordDate',
				headerName: 'Record Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'paymentDate',
				headerName: 'Payment Date',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'estimatedAnnualIncome',
				headerName: 'Estimated Annual Income',
				renderCell: (params) =>
					formatCurrency(params.row.estimatedAnnualIncome, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'price',
				headerName: 'Price',
				renderCell: (params) =>
					formatCurrency(params.row.price, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'bookPrice',
				headerName: 'Book Price',
				renderCell: (params) =>
					formatCurrency(params.row.bookPrice, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getPercentColumnDefinition(),
				field: 'bookYield',
				headerName: 'Book Yield',
				valueGetter: (params) => params.row.bookYield,
			},
			{
				...getPercentColumnDefinition(),
				field: 'purchaseYield',
				headerName: 'Purchase Yield',
				valueGetter: (params) => params.row.purchaseYield,
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'originalPrice',
				headerName: 'Original Price',
				renderCell: (params) =>
					formatCurrency(params.row.originalPrice, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getDecimalColumnDefinition(),
				field: 'duration',
				headerName: 'Duration',
			},
			{
				field: 'durationGroup',
				headerName: 'Duration Group',
			},
			{
				field: 'balanceSheetClassification',
				headerName: 'Balance Sheet Classification',
			},
			{
				field: 'lotId',
				headerName: 'Lot ID',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'pendingPurchasePayables',
				headerName: 'Pending Purchase Payables',
				renderCell: (params) =>
					formatCurrency(params.row.pendingPurchasePayables, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'pendingSaleReceivables',
				headerName: 'Pending Sale Receivables',
				renderCell: (params) =>
					formatCurrency(params.row.pendingSaleReceivables, {
						currency: params.row.currencyCode,
					}),
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'baseTradedMarketValue',
				headerName: 'Base Traded Market Value',
				renderCell: (params) =>
					formatCurrency(params.row.baseTradedMarketValue, {
						currency: params.row.currencyCode,
					}),
			},
		];

		if (cash4.isAuthor) {
			columns.unshift({
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return <RowActionButton row={params.row} />;
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			});
		}

		return columns;
	}, [cash4.isAuthor]);

	return (
		<>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				rowGap={1}
				sx={{ height: '100%' }}
			>
				<Grid container item xs={1} columnGap={3}>
					<Grid item>
						<T4DateRangePicker
							value={dateRange}
							onChange={(newValue) => {
								handleDateRangeChange(newValue);
							}}
							disableFuture
							showShortcuts
							shortcutResetDefaults={defaultDateRange}
							id={stonlyIds.investmentsHoldingsRoot}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ height: '65vh' }}>
					<UserPreferencesDataGrid
						stonlyId={stonlyIds.investmentsHoldingsGrid}
						getRowId={getRowId}
						tableKey="holdingsPage"
						columns={columns}
						rows={holdings}
						loading={holdingsLoading}
						calculateColumnWidths
						columnVisibilityModel={{
							accountNumber: false,
							federalTaxCost: false,
							unrealizedGainLossCost: false,
							openingBalanceAmount: false,
							openingBalanceUnits: false,
							openingBalancePrice: false,
							closingBalancePrice: false,
							mtdEarnings: false,
							baseSettledMarketValue: false,
							localSettledMarketValue: false,
							localTradedMarketValue: false,
							currentYield: false,
							priceDate: false,
							firstIncomePaymentDate: false,
							secondIncomePaymentDate: false,
							monthToDateEarningsRate: false,
							monthToDateFees: false,
							sourceAccountName: false,
							portfolioId: false,
							portfolioTypeCode: false,
							portfolioTypeCodeDescription: false,
							portfolioName: false,
							custodianId: false,
							custodianName: false,
							subCustodianId: false,
							subCustodianName: false,
							baseMarketValuePlusAccrued: false,
							localAccruedInterest: false,
							tradeDate: false,
							settlementDate: false,
							originalLotId: false,
							dividendAmount: false,
							expectedDividendDate: false,
							recordDate: false,
							paymentDate: false,
							estimatedAnnualIncome: false,
							price: false,
							bookPrice: false,
							bookYield: false,
							purchaseYield: false,
							originalPrice: false,
							duration: false,
							durationGroup: false,
							balanceSheetClassification: false,
							lotId: false,
							pendingPurchasePayables: false,
							pendingSaleReceivables: false,
							baseTradedMarketValue: false,
						}}
						sortModel={sortModel}
						onSortModelChange={(newSortModel) => {
							setSortModel(newSortModel);
						}}
						initialState={{
							pinnedColumns: { left: ['actions'] },
						}}
						showToolbar
						showCustomViewButton
						slotProps={{
							toolbar: {
								quickFilterProps: {
									inputProps: {
										...stonlyData({
											id: stonlyIds.investmentsHoldingsGridFilter,
										}),
									},
								},
							},
						}}
						slots={{
							noRowsOverlay: customNoRowOverlay,
						}}
						pagination
						hideFooter={false}
						autoPageSize
						onRowSelectionModelChange={(model: GridRowSelectionModel) => {
							marketValueSelection.setRowSelectionHandler(model);
						}}
					/>
				</Grid>
				{marketValueSelection.isSelectedTotalValid &&
					marketValueSelection.selectedCount >= 2 && (
						<Grid item xs="auto">
							<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
								{'The '}
								<C4AlertBold>{`${
									marketValueSelection.selectedCurrencySource ===
									CurrencySource.Account
										? 'account currency'
										: 'reporting currency'
								}`}</C4AlertBold>
								{' market value of the selected rows is '}
								<C4AlertBold>
									{`${formatCurrency(marketValueSelection.selectedTotal, {
										currency: marketValueSelection.selectedCurrency,
									})} ${marketValueSelection.selectedCurrency}`}
								</C4AlertBold>
								{'.'}
							</T4Alert>
						</Grid>
					)}
				{!marketValueSelection.isSelectedTotalValid &&
					marketValueSelection.selectedCount >= 2 && (
						<Grid item xs="auto">
							<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
								{'The '}
								<C4AlertBold>{`${
									marketValueSelection.selectedCurrencySource ===
									CurrencySource.Account
										? 'account currency'
										: 'reporting currency'
								}`}</C4AlertBold>
								{
									' market value of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
								}
							</T4Alert>
						</Grid>
					)}
			</Grid>
		</>
	);
};
