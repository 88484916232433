/* eslint-disable mobx/missing-observer */
import { DateRange } from '@mui/x-date-pickers-pro';
import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';
import { UseCash4ConfigurationsProps } from 'features/_shared/_providers/cash4ConfigurationsProvider';
import { CurrencyConfiguration } from 'features/cash4/balances/balancesModel';
import { DeleteTransactionResponse } from 'modules/clients/customer-api/src/transactions';
import moment, { Moment } from 'moment';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { CurrencyOption, TransactionListItem } from '../models';
import { defaultCurrencyCode } from 'utilities/currencyUtils';

//#region Context

type TransactionsContextProps = {
	dateRange: DateRange<Moment>;
	handleDateRangeChange: (dateRange: DateRange<Moment>) => void;
	defaultDateRange: DateRange<Moment>;
	fetchTransactions: (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[],
	) => Promise<TransactionListItem[]>;
	currencyOption: CurrencyOption;
	configurations: UseCash4ConfigurationsProps['data'];
	handleCurrencyOptionChange: (value: CurrencyOption['id']) => void;
	deleteCashTransactionApiCall: (
		transactionId: string,
	) => Promise<DeleteTransactionResponse>;
};

const TransactionsContext = createContext<TransactionsContextProps>({
	dateRange: [moment(), moment()],
	handleDateRangeChange: () => {},
	defaultDateRange: [moment(), moment()],
	fetchTransactions: async () => [],
	currencyOption: { id: 'original', value: 'Account Currency' },
	configurations: undefined,
	handleCurrencyOptionChange: () => {},
	deleteCashTransactionApiCall: async () => ({
		error: null,
		errors: null,
		failure: false,
		success: true,
		value: '',
	}),
});

//#endregion

//#region Provider

export type TransactionProviderProps = {
	children: ReactNode;
};

export const TransactionsProvider: FC<TransactionProviderProps> = ({
	children,
}) => {
	const { customerApiClient } = useClients();
	const { data: configurations } = useCash4ConfigurationsQuery();
	const { transactionStartDate, transactionEndDate } = useSessionStorage();

	const defaultDateRange: DateRange<Moment> = [
		moment().subtract(8, 'days').startOf('day'),
		moment().endOf('day'),
	];

	const [dateRange, setDateRange] = useState<DateRange<Moment>>([
		moment(transactionStartDate),
		moment(transactionEndDate),
	]);
	const [currencyConfiguration, setCurrencyConfiguration] =
		useState<CurrencyConfiguration>({} as CurrencyConfiguration);

	const currencyOptions: CurrencyOption[] = useMemo(
		() => [
			{ id: 'original', value: 'Account Currency' },
			{
				id: 'reporting',
				value: `Reporting Currency (${currencyConfiguration?.reportingCurrencyCode?.code})`,
			},
		],
		[currencyConfiguration?.reportingCurrencyCode?.code],
	);

	const [currencyOption, setCurrencyOption] = useState<CurrencyOption>(
		currencyOptions[0],
	);

	const handleCurrencyOptionChange = useCallback(
		(value: CurrencyOption['id']) => {
			const selectedOption = currencyOptions.find(
				(option) => option.id === value,
			);

			if (selectedOption) {
				setCurrencyOption(selectedOption);
			}
		},
		[currencyOptions],
	);

	const handleDateRangeChange = useCallback((dateRange: DateRange<Moment>) => {
		setDateRange(dateRange);
	}, []);

	const fetchTransactions = useCallback(
		async (
			dateRange: DateRange<Moment>,
			legalEntityGroupIds: string[] = [],
		): Promise<TransactionListItem[]> => {
			const queryParams = new URLSearchParams();
			if (dateRange?.[0]) {
				queryParams.append('startDate', dateRange[0].format('YYYY-MM-DD'));
			}
			if (dateRange?.[1]) {
				queryParams.append('endDate', dateRange[1].format('YYYY-MM-DD'));
			}

			legalEntityGroupIds.forEach((id) => {
				queryParams.append('legalEntityGroupIds', id);
			});

			const response = await customerApiClient.transactions.all({
				startDate: dateRange?.[0]?.format('YYYY-MM-DD') ?? '',
				endDate: dateRange?.[1]?.format('YYYY-MM-DD') ?? '',
				legalEntityGroupIds: legalEntityGroupIds,
			});

			if (response.data.data) {
				response.data.data.forEach((x) => {
					x.number.accountCurrencyCode = x.currency.toUpperCase();
					x.number.reportingCurrencyCode =
						configurations?.reportingCurrencyCode || defaultCurrencyCode;
				});
			}

			return response.data.data || [];
		},
		[customerApiClient.transactions, configurations?.reportingCurrencyCode],
	);

	const fetchCurrencyConfiguration = useCallback(async () => {
		const response =
			await customerApiClient.cash4.configurations.getCurrencyConfiguration();
		const currencyConfiguration = response.data.value;

		const safeCurrencyConfiguration: CurrencyConfiguration = {
			reportingCurrencyCode: {
				name: currencyConfiguration.reportingCurrencyCode.name || '',
				code: currencyConfiguration.reportingCurrencyCode.code || '',
			},
			activeCurrencies: currencyConfiguration.activeCurrencies.map(
				(currency) => ({
					name: currency.name || '',
					code: currency.code || '',
				}),
			),
		};

		setCurrencyConfiguration(safeCurrencyConfiguration);
	}, [customerApiClient.cash4.configurations]);

	useEffect(() => {
		fetchCurrencyConfiguration();
	}, [fetchCurrencyConfiguration]);

	const deleteCashTransactionApiCall = useCallback(
		async (transactionId: string) => {
			try {
				const response = await customerApiClient.transactions.deleteTransaction(
					transactionId,
				);
				if (response.data.error) {
					throw new Error(response.data.error);
				}
				return response.data;
			} catch (error) {
				throw error;
			}
		},
		[customerApiClient.transactions],
	);

	return (
		<TransactionsContext.Provider
			value={{
				dateRange,
				handleDateRangeChange,
				defaultDateRange,
				configurations: configurations,
				fetchTransactions,
				currencyOption,
				handleCurrencyOptionChange,
				deleteCashTransactionApiCall,
			}}
		>
			{children}
		</TransactionsContext.Provider>
	);
};

//#endregion

export type UseTransactionsProps = TransactionsContextProps;

export function useTransactions(): UseTransactionsProps {
	const context = useContext(TransactionsContext);
	if (context === undefined) {
		throw new Error(
			'useTransactions must be used within a TransactionsProvider',
		);
	}
	return context;
}
