import { yupResolver } from '@hookform/resolvers/yup';
import {
	Box,
	Collapse,
	Divider,
	Grid,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
	SubmitPaymentRequest,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import {
	Payment,
	PaymentAmount,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4ProblemDetails } from 'modules/clients/types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { CancellationModal } from 'shared/components/CancellationModal';
import { T4CurrencyInput } from 'shared/components/t4CurrencyInput';
import { T4DatePicker } from 'shared/components/t4DatePicker';
import { useClients } from 'shared/hooks/useClients';
import { formatDate } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useGetAllPaymentTemplates } from './hooks/usePaymentTemplates';
import { PaymentPartyInformation } from './paymentPartyInformationBox';
import { SubmitPaymentFormValidator } from './validators';

const stonlyIds = {
	submitButton: 'submit-payment-submit-button',
	cancelButton: 'submit-payment-cancel-button',
	initiatorSection: 'submit-payment-initiator-section',
	payeeSection: 'submit-payment-payee-section',
	detailsSection: 'submit-payment-details-section',
	paymentInformationSection: 'submit-payment-payment-information-section',
};

export type SubmitPaymentForm = {
	template: PaymentTemplate | null;
	instructedAmount: PaymentAmount;
	valueDate: string;
	referenceData: string | null;
};

const defaultSubmitPaymentForm: SubmitPaymentForm = {
	template: null,
	instructedAmount: {
		value: null,
		currencyCode: null,
	},
	valueDate: formatDate(moment())!,
	referenceData: null,
};

type SubmitPaymentDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	refetch?: () => Promise<QueryObserverResult<Payment[], Error>>;
	template?: PaymentTemplate | null;
};

export const SubmitPaymentDrawer: FC<SubmitPaymentDrawerProps> = ({
	isOpen,
	onClose,
	refetch,
	template,
}) => {
	// #region State

	const theme = useTheme();
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const { handleSubmit, control, reset, formState, watch, setValue } =
		useForm<SubmitPaymentForm>({
			defaultValues: defaultSubmitPaymentForm,
			resolver: yupResolver(SubmitPaymentFormValidator),
		});

	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const resetDrawer = useCallback(() => {
		reset(defaultSubmitPaymentForm);
		setIsLoading(false);
		setErrors([]);
		setIsCancellationModalOpen(false);
	}, [reset]);

	const {
		isLoading: areTemplatesLoading,
		isFetching: areTemplatesFetching,
		data,
		error: loadingTemplatesError,
	} = useGetAllPaymentTemplates(isOpen);

	useEffect(() => {
		if (loadingTemplatesError) {
			onClose();
			resetDrawer();
		}
	}, [loadingTemplatesError, onClose, resetDrawer]);

	useEffect(() => {
		if (template) {
			reset({
				...defaultSubmitPaymentForm,
				template: template,
				instructedAmount: {
					...defaultSubmitPaymentForm.instructedAmount,
					currencyCode: template.currencyCode,
				},
				referenceData: template.referenceData,
			});
		}
	}, [template, reset]);

	// #endregion

	// #region Memoized Values

	const templates = useMemo(
		() =>
			data?.filter(
				(x) =>
					x.currentStatus ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Approved] ||
					x.currentStatus ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Created],
			),
		[data],
	);

	const selectedTemplate = watch('template');
	const amount = watch('instructedAmount.value');

	const TemplatePaymentInformation = useMemo(() => {
		const gridRowSx = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		};
		const typographySx = {
			display: 'inline',
		};
		return (
			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12} sx={gridRowSx}>
					<Typography variant="body1" sx={{ fontWeight: 500, ...typographySx }}>
						Payment Type
					</Typography>
					<Typography variant="body1" sx={typographySx}>
						{selectedTemplate?.paymentType}
					</Typography>
				</Grid>
				<Grid item xs={12} sx={gridRowSx}>
					<Typography variant="body1" sx={{ fontWeight: 500, ...typographySx }}>
						Payment Currency
					</Typography>
					<Typography variant="body1" sx={typographySx}>
						{selectedTemplate?.currencyCode}
					</Typography>
				</Grid>
			</Grid>
		);
	}, [selectedTemplate]);

	const InitiatorInformation = useMemo(
		() => (
			<PaymentPartyInformation
				party={selectedTemplate?.initiator ?? null}
				partyType="Initiator"
			/>
		),
		[selectedTemplate?.initiator],
	);

	const PayeeInformation = useMemo(
		() => (
			<PaymentPartyInformation
				party={selectedTemplate?.payee ?? null}
				partyType="Payee"
			/>
		),
		[selectedTemplate?.payee],
	);

	const CancellationModalMemo = useMemo(
		() => (
			<CancellationModal
				isOpen={isCancellationModalOpen}
				resourceType="payment"
				variant="create"
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={() => {
					onClose();
					resetDrawer();
				}}
			/>
		),
		[isCancellationModalOpen, onClose, resetDrawer],
	);

	// #endregion

	// #region Submit Functions

	const onSubmit = useCallback(
		async (data: SubmitPaymentForm) => {
			try {
				setErrors([]);
				setIsLoading(true);

				const { template, ...request } = data;
				const response =
					await applicationApiClient.payments4.paymentTemplates.submitPayment({
						id: data.template!.id,
						data: trimStringsInObject({
							paymentTemplateVersion: data.template!.version,
							...request,
						}) as SubmitPaymentRequest,
					});

				if (response.status === 201 && response.data) {
					onClose();
					resetDrawer();
					refetch?.();
					enqueueSnackbar('Payment submitted successfully.', {
						variant: 'success',
					});
				} else if (response.status === 400 && response.data) {
					setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
					errorsRef?.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				} else throw new Error();
			} catch {
				enqueueSnackbar(
					'An unexpected error occured and we were unable to submit payment. Please try again later.',
					{
						variant: 'error',
					},
				);
			} finally {
				setIsLoading(false);
			}
		},
		[
			applicationApiClient,
			errorsRef,
			onClose,
			resetDrawer,
			refetch,
			enqueueSnackbar,
		],
	);

	// #endregion

	return (
		<T4DrawerBase
			title="Create Payment from Template"
			open={isOpen}
			initializing={areTemplatesLoading || areTemplatesFetching}
			loading={isLoading}
			onClose={() => {
				if (formState.isDirty) setIsCancellationModalOpen(true);
				else {
					onClose();
					resetDrawer();
				}
			}}
			actions={[
				<DrawerCancelButton
					stonlyId={stonlyIds.cancelButton}
					onCancel={() => {
						if (formState.isDirty) setIsCancellationModalOpen(true);
						else {
							onClose();
							resetDrawer();
						}
					}}
				/>,
				<DrawerSubmitButton
					stonlyId={stonlyIds.submitButton}
					onSubmit={handleSubmit(onSubmit)}
					disabled={!formState.isDirty || amount === null || isLoading}
				/>,
			]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid item xs={12}>
					<Controller
						name="template"
						control={control}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<T4Autocomplete<PaymentTemplate>
								id="template"
								label="Template"
								options={templates ?? []}
								value={value}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								getOptionDisabled={(option) =>
									!(
										option.currentStatus ===
											PaymentTemplateStatusTypes[
												PaymentTemplateStatusTypes.Approved
											] && !option.hasDraftVersion
									)
								}
								renderOption={(props, option, _, ownerState) => (
									<Box key={option.id}>
										<Tooltip
											title={
												ownerState.getOptionDisabled?.(option) ? (
													<Box>
														<Typography
															variant="caption"
															sx={{
																color: theme.palette.primary.contrastText,
															}}
														>
															This template cannot currently be used for
															payments. An approver must verify and approve this
															template before use.
														</Typography>
													</Box>
												) : (
													''
												)
											}
											placement="left"
											arrow
										>
											<span>
												<Box component="li" {...props}>
													{ownerState.getOptionLabel(option)}
												</Box>
											</span>
										</Tooltip>
									</Box>
								)}
								onChange={(_, value) => {
									onChange(value);
									setValue(
										'instructedAmount.currencyCode',
										value?.currencyCode ?? null,
									);
									setValue('referenceData', value?.referenceData ?? null);
								}}
								error={!!error}
								helperText={error && error.message}
								required
							/>
						)}
					/>
				</Grid>

				<Grid container item>
					<Collapse in={!!selectedTemplate}>
						<Grid container sx={{ gap: 2 }}>
							<Grid
								container
								item
								xs={12}
								spacing={1}
								{...stonlyData({ id: stonlyIds.detailsSection })}
							>
								{TemplatePaymentInformation}

								<Grid item xs={6}>
									<Controller
										name="valueDate"
										control={control}
										render={({
											field: { onChange, value },
											fieldState: { error },
										}) => (
											<T4DatePicker
												label="Value Date"
												value={moment(value)}
												onChange={(date) => onChange(formatDate(date))}
												disablePast
												slotProps={{
													textField: {
														error: !!error,
														helperText: error && error.message,
														required: true,
													},
												}}
											/>
										)}
									/>
								</Grid>

								<Grid item xs={6}>
									<Controller
										name="instructedAmount.value"
										control={control}
										render={({
											field: { onChange, value },
											fieldState: { error },
										}) => (
											<T4CurrencyInput
												label="Amount"
												value={value}
												onValueChange={({ floatValue }) =>
													onChange(floatValue ?? null)
												}
												locale="en-US"
												currencyCode={
													watch('instructedAmount.currencyCode') ?? undefined
												}
												error={!!error}
												helperText={error && error.message}
												required
												allowNegative={false}
												fixedDecimalScale
											/>
										)}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid
								container
								item
								xs={12}
								spacing={1}
								{...stonlyData({ id: stonlyIds.initiatorSection })}
							>
								{InitiatorInformation}
							</Grid>

							<Grid item xs={12}>
								<Divider />
							</Grid>

							<Grid
								container
								item
								xs={12}
								spacing={1}
								{...stonlyData({ id: stonlyIds.payeeSection })}
							>
								{PayeeInformation}
							</Grid>

							<Grid
								item
								xs={12}
								{...stonlyData({ id: stonlyIds.paymentInformationSection })}
							>
								<Controller
									name="referenceData"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4TextFieldV2
											label="Payment Information"
											value={value ?? ''}
											onChange={(value: string) => {
												if (isStringUndefinedOrNullOrWhitespace(value))
													onChange(null);
												else {
													onChange(value);
												}
											}}
											minRows={4}
											maxRows={4}
											multiline
											error={!!error}
											helperText={`${value?.length ?? 0}/140${
												error?.message ? ' ' + error.message : ''
											}`}
											inputProps={{
												maxLength: 140,
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Collapse>
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12} ref={errorsRef}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			{CancellationModalMemo}
		</T4DrawerBase>
	);
};
